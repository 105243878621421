import React from 'react';

function SearchBox({ label, onSearchChange }) {
  return (
    <div className='search-container'>
      <div className='search'>
        {label}
        <input type='search' placeholder='Search' onChange={onSearchChange} />
      </div>
    </div>
  );
}

export default SearchBox;
