import Login from '../../Pages/Login';
import Logout from '../../Pages/Logout';

import { useAuth0 } from '@auth0/auth0-react';

const AuthenticationButton = ({ click }) => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? <Logout click={click} /> : <Login click={click} />;
};

export default AuthenticationButton;
