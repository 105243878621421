import React from 'react';

export const Scroll = props => {
  return (
    <div
      style={{
        overflow: 'scroll',
        height: `calc(100vh - ${props.offset ?? '80px'})`,
      }}
    >
      {props.children}
    </div>
  );
};
export default Scroll;
