import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Catalog from './Pages/Catalog';
import Home from './Pages/Home';
import Pricebook from './Pages/Pricebook';
import ServiceOpportunities from './Pages/ServiceOpportunities';
import Navbar from './components/Navbar';
import { AuthenticationGuard } from './utils/auth0/authentication-guard';

const OLD_ROUTES = [
  {
    oldPathname: '/consult',
    newUrl: 'https://sales.brilliantos.com/new-lead',
  },
  {
    oldPathname: '/budget',
    newUrl: 'https://sales.brilliantos.com/budget',
  },
  {
    oldPathname: '/projects',
    newUrl: 'https://sales.brilliantos.com/projects',
  },
  {
    oldPathname: '/opportunities',
    newUrl: 'https://sales.brilliantos.com/opportunities',
  },
];

function redirectOldRoutes() {
  const { pathname } = window.location;
  const oldRoute = OLD_ROUTES.find(route =>
    pathname.includes(route.oldPathname),
  );
  if (oldRoute) {
    window.location.href = oldRoute.newUrl;
  }
}

redirectOldRoutes();

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Navbar />}>
        <Route index element={<Home />} />
        <Route
          path='/service'
          element={<AuthenticationGuard component={ServiceOpportunities} />}
        />
        <Route
          path='/catalog'
          element={<AuthenticationGuard component={Catalog} />}
        />
        <Route
          path='/pricebook'
          element={<AuthenticationGuard component={Pricebook} />}
        />
      </Route>
    </Routes>
  );
};

export default App;
