import { BUDGET_ACTION_TYPES } from './budget.types';

export const BUDGET_INITIAL_STATE = {
  budget: [],
};

export const budgetReducer = (state = BUDGET_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case BUDGET_ACTION_TYPES.SET_BUDGET_ITEM:
      return { ...state, budget: payload };
    default:
      return state;
  }
};
