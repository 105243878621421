import '../App.css';
import { apiUrl } from '../utils/api-url/URL';
import React, { useState } from 'react';
import { useAuthHeader } from '../utils/utils';
import { api } from '../utils/api';

const Catalog = () => {
  const authHeader = useAuthHeader();

  const [state, setState] = useState({
    search: 'scope',
    catalog: false,
    click: false,
    catDown: '',
  });

  const handleInputChange = event => {
    console.log(event);
    console.log(event.target.value);
    setState(prevState => ({ ...prevState, search: event.target.value }));
  };

  const downloadCatalog = async () => {
    console.log(state.search);
    setState(prevState => ({ ...prevState, click: true }));

    const { data } = await api.get(`/dt/catalog/${state.search}`, {
      headers: authHeader,
    });

    console.log(data, 'Get Catalog');
    setState(prevState => ({
      ...prevState,
      down1Name: 'Download Single CSV',
      catDown: `${apiUrl}/download/${data.fileName}`,
    }));
  };

  return (
    <div className='catelog'>
      <h2>Download Catalog</h2>
      <div className='task'>
        <h3>Search for Brand or Scope</h3>
        <input
          type='text'
          onChange={handleInputChange}
          placeholder='If left blank it will download Scope'
        ></input>
        <button onClick={downloadCatalog} className='btn'>
          Get Catalog
        </button>
        <a href={state.catDown}>
          <button className={state.click === false ? 'noDisp' : 'btn'}>
            Download Catalog File
          </button>
        </a>
      </div>
    </div>
  );
};

export default Catalog;
