import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { api } from '../utils/api';
import { useAuthHeader } from '../utils/utils';
import ServiceQuote from './ServiceQuote';

function ServiceOpportunity({ opportunity }) {
  const authHeader = useAuthHeader();

  const {
    data: quotes,
    mutate: getQuotes,
    isLoading,
  } = useMutation({
    mutationFn: async () => {
      const { data } = await api.get(
        `/dt/opportunities/${opportunity.id}/quotes`,
        {
          headers: authHeader,
        },
      );

      return data;
    },
  });

  return (
    <div className='task'>
      <div>
        <h2>{opportunity.client}</h2>
        <p>{opportunity.name}</p>
        <p>{opportunity.number}</p>
        <p>{opportunity.stage}</p>
        <p>${opportunity.price}</p>
        <p>{opportunity.owner}</p>
        <p>{opportunity.archived}</p>
      </div>

      <button className='btn' onClick={getQuotes}>
        Get Service Quotes
      </button>

      {isLoading && <p className='text-center'>Loading quotes...</p>}

      {quotes?.map(quote => (
        <ServiceQuote key={quote.id} quote={quote} />
      ))}
    </div>
  );
}

export default ServiceOpportunity;
