import React from 'react';
import { PacmanLoader } from 'react-spinners';

const Loading = ({ title }) => (
  <div className='overlay'>
    <PacmanLoader size={60} color={'#fff'} />
    <p className='text-lg text-white'>{title}</p>
  </div>
);

export default Loading;
