import './directory-item.styles.scss';
import { Link } from 'react-router-dom';

const DirectoryItem = ({ category }) => {
  const { imageUrl, title, url } = category;
  return (
    <div className='directory-item-container'>
      <div
        className='background-image'
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <Link className='body' to={`/${url}`}>
        <h2>{title}</h2>
      </Link>
    </div>
  );
};

export default DirectoryItem;
