import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import Scroll from '../components/Scroll';
import SearchBox from '../components/SearchBox';
import ServiceOpportunity from '../components/ServiceOpportunity';
import Loading from '../components/Loading';
import { api } from '../utils/api';
import { useAuthHeader } from '../utils/utils';
import { useDebounce } from '../utils/hooks/useDebounce';
import ServiceOpportunity2 from '../components/ServiceOpportunity2';

export default function ServiceOpportunities() {
  const authHeader = useAuthHeader();

  const [searchfield, setSearchfield] = useState('');
  const debouncedSearchfield = useDebounce(searchfield, 300);

  const { data: opportunities, isInitialLoading } = useQuery({
    queryKey: ['opportunities', authHeader],
    queryFn: async () => {
      const res = await api.get(`/dt/service/opportunities`, {
        headers: authHeader,
      });

      return res.data
        .flatMap(opp => opp.opportunities)
        .sort((a, b) =>
          b.number
            .split('-')[1]
            .localeCompare(a.number.split('-')[1], undefined, {
              numeric: true,
              sensitivity: 'base',
            }),
        );
    },
    enabled: !!authHeader,
  });

  const onSearchChange = event => {
    setSearchfield(event.target.value);
  };

  const filteredOpportunities = useMemo(
    () =>
      opportunities?.filter(opp => {
        return (
          !opp.archived &&
          (opp.client
            .toLocaleLowerCase()
            .includes(debouncedSearchfield.toLocaleLowerCase()) ||
            opp.number
              .toLocaleLowerCase()
              .includes(debouncedSearchfield.toLocaleLowerCase()) ||
            opp.name
              .toLocaleLowerCase()
              .includes(debouncedSearchfield.toLocaleLowerCase()))
        );
      }),
    [debouncedSearchfield, opportunities],
  );

  return (
    <Scroll>
      <SearchBox onSearchChange={onSearchChange} />

      {isInitialLoading && <Loading />}

      {filteredOpportunities?.map(opportunity => {
        if (opportunity.number.split('-')[0] === 'TP') {
          return (
            <ServiceOpportunity2
              key={opportunity.id}
              opportunity={opportunity}
            />
          );
        }

        return (
          <ServiceOpportunity key={opportunity.id} opportunity={opportunity} />
        );
      })}
    </Scroll>
  );
}
