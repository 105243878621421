import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../components/Navbar.css';

const Login = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    !isAuthenticated && (
      <span className='nav-links' onClick={loginWithRedirect}>
        Log in
      </span>
    )
  );
};

export default Login;
