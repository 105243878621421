import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export function useAuthHeader() {
  const { getAccessTokenSilently } = useAuth0();
  const [authHeader, setAuthHeader] = useState();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        setAuthHeader({ Authorization: `Bearer ${token}` });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently]);

  return authHeader;
}
