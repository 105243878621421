import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { api } from '../utils/api';
import { useAuthHeader } from '../utils/utils';
import DownloadFiles from './DownloadFiles';

function ServiceQuote({ quote }) {
  const authHeader = useAuthHeader();

  const {
    mutate: convertQuote,
    data: downloads,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: async () => {
      const { data } = await api.post(
        `/service/quotes/convert`,
        { quoteId: quote.id },
        {
          headers: authHeader,
        },
      );

      console.log('convert quote result: ', data);

      if (data.error) {
        switch (data.error) {
          case "unsupported operand type(s) for +: 'float' and 'str'":
            alert(
              `Error code: ${data.error}\n\nMost likely a labor used does not match the lookup table. Please create a IT Support Issues Tracker entry with the error listed above and steps to reproduce.`,
            );
            break;

          default:
            alert(
              `There was a problem with the conversion\nError code: ${data.error}\n\nMake sure no direct labor items were used in the quote.\n\nIf everything looks good, please create a IT Support Issues Tracker entry with the error listed above and steps to reproduce.`,
            );
        }

        throw new Error(data.error);
      }

      return data;
    },
  });

  return (
    <div className='task'>
      <h3>{quote.name}</h3>
      <p>{quote.number}</p>
      <p>{quote.stage}</p>
      <p>${quote.price}</p>
      <p>Version {quote.version}</p>

      <div>
        <button className='btn' onClick={convertQuote}>
          Convert Service Quote
        </button>

        {isLoading && <p className='text-center'>Converting quote...</p>}

        {isError && (
          <p className='text-center'>
            There was an error converting the quote.
          </p>
        )}

        {downloads && <DownloadFiles downloads={downloads} />}
      </div>
    </div>
  );
}

export default ServiceQuote;
