import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { api } from '../utils/api';
import { useAuthHeader } from '../utils/utils';

/**
 * @param {{ downloads: { file: string; name: string; }[]}} props
 */
function DownloadFiles({ downloads }) {
  const authHeader = useAuthHeader();

  const { mutate: downloadFile } = useMutation({
    mutationFn: async ({ file }) => {
      const { data } = await api.get(`/download/${file}`, {
        responseType: 'blob',
        headers: authHeader,
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);
      link.download = file;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    },
  });

  return (
    <div>
      {downloads.map(download => (
        <button
          key={download.name}
          className='btn'
          onClick={() => downloadFile(download)}
        >
          {download.name}
        </button>
      ))}
    </div>
  );
}

export default DownloadFiles;
