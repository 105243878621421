export const BUDGETWALKTHROUGH_ACTION_TYPES = {
  SET_BUDGETWALKTHROUGH_ITEM: 'SET_BUDGETWALKTHROUGH_ITEM',
  SET_IS_SYSTEM_HIDDEN: 'SET_IS_SYSTEM_HIDDEN',
  SET_AUDIO_SYSTEM: 'SET_AUDIO_SYSTEM',
  SET_VIDEO_DIST_SYSTEM: 'SET_VIDEO_DIST_SYSTEM',
  SET_SOURCE: 'SET_SOURCE',
  SET_SPEAKERS: 'SET_SPEAKERS',
  SET_SURROUNDSOUND: 'SET_SURROUNDSOUND',
  SET_CONFIG_TYPE: 'SET_CONFIG_TYPE',
  SET_CONFIG_SHAPE: 'SET_CONFIG_SHAPE',
  SET_CONFIG_SIZE: 'SET_CONFIG_SIZE',
  SET_CONFIG_FLOORS: 'SET_CONFIG_FLOORS',
  SET_CONFIG_NETWORK: 'SET_CONFIG_NETWORK',
  SET_CONFIG_PREWIRE: 'SET_CONFIG_PREWIRE',
  SET_CONFIG_PREWIRETYPE: 'SET_CONFIG_PREWIRETYPE',
  SET_CONFIG_WAPS: 'SET_CONFIG_WAPS',
  SET_SPEAKERS_SONOS_EXISTING: 'SET_SPEAKERS_SONOS_EXISTING',
  SET_SPEAKERS_SONOS_ARCH: 'SET_SPEAKERS_SONOS_ARCH',
  SET_SPEAKERS_SONOS_SMALL: 'SET_SPEAKERS_SONOS_SMALL',
  SET_SPEAKERS_SONOS_INVISIBLE: 'SET_SPEAKERS_SONOS_INVISIBLE',
  SET_SPEAKERS_C4_EXISTING: 'SET_SPEAKERS_C4_EXISTING',
  SET_SPEAKERS_C4_ARCH: 'SET_SPEAKERS_C4_ARCH',
  SET_SPEAKERS_C4_SMALL: 'SET_SPEAKERS_C4_SMALL',
  SET_SPEAKERS_C4_INVISIBLE: 'SET_SPEAKERS_C4_INVISIBLE',
  SET_SPEAKERS_C4_HIFI: 'SET_SPEAKERS_C4_HIFI',
  SET_SPEAKERS_QUANTITY: 'SET_SPEAKERS_QUANTITY',
  SET_SURROUND_QUANTITY: 'SET_SURROUND_QUANTITY',
  SET_TV_SIZES: 'SET_TV_SIZES',
  SET_TOTALS: 'SET_TOTALS',
  SET_LIGHTING_SYSTEM: 'SET_LIGHTING_SYSTEM',
  SET_LIGHTING_DEVICES: 'SET_LIGHTING_DEVICES',
  SET_SHADE_TYPE: 'SET_SHADE_TYPE',
  SET_SHADE_STYLE: 'SET_SHADE_STYLE',
  SET_LIGHTING_REPEATERS: 'SET_LIGHTING_REPEATERS',
  SET_LIGHTING_PANELS: 'SET_LIGHTING_PANELS',
  SET_SHADE_PANELS: 'SET_SHADE_PANELS',
  SET_SECURITY_DEVICES: 'SET_SECURITY_DEVICES',
  SET_SECURITY_TYPE: 'SET_SECURITY_TYPE',
  SET_CAMERA_TYPE: 'SET_CAMERA_TYPE',
  SET_CAMERA_SIZE: 'SET_CAMERA_SIZE',
  SET_PROJECT_INFO: 'SET_PROJECT_INFO',
  SET_SMART_HOME: 'SET_SMART_HOME',
  SET_MISC_TOTAL: 'SET_MISC_TOTAL',
  UPDATE_MISC_ITEMS: 'UPDATE_MISC_ITEMS',
};
