import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { api } from '../utils/api';
import { useAuthHeader } from '../utils/utils';

function ServiceOpportunity2({ opportunity }) {
  const queryClient = useQueryClient();
  const authHeader = useAuthHeader();

  const { mutate: convertQuote, isLoading } = useMutation({
    mutationFn: async () => {
      const { data } = await api.post(
        `/service/quotes/convert2`,
        {
          dtOppId: opportunity.id,
          tpOppNumber: opportunity.number.split('TP-')[1],
        },
        {
          headers: authHeader,
        },
      );

      console.log('convert quote result: ', data);

      if (!data.Success) {
        alert(
          `There was a problem with the conversion.\n\nError: ${data.Message}\n\nMake sure no direct labor items were used in the quote.\n\nIf everything looks good, please create a IT Support Issues Tracker entry with the error listed above and steps to reproduce.`,
        );

        throw new Error(data.Message);
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries('opportunities');
    },
  });

  return (
    <div className='task'>
      <div>
        <h2>{opportunity.client}</h2>
        <p>{opportunity.name}</p>
        <p>{opportunity.number}</p>
        <p>{opportunity.stage}</p>
        <p>${opportunity.price}</p>
        <p>{opportunity.owner}</p>
        <p>{opportunity.archived}</p>
      </div>

      <button className='btn' onClick={convertQuote}>
        Convert Quote
      </button>

      {isLoading && <p className='text-center'>Converting quote...</p>}
    </div>
  );
}

export default ServiceOpportunity2;
