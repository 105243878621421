import React, { useState } from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';
import '../App.css';
import DownloadFiles from '../components/DownloadFiles';
import { api } from '../utils/api';
import { useAuthHeader } from '../utils/utils';

const Pricebook = () => {
  const authHeader = useAuthHeader();

  const [state, setState] = useState({
    dt: '',
    tp: '',
    nw: '',
    download: '',
    brand: '',
    wait: 'Process Files',
    show: 'btn',
    loading: false,
    tpImport: '',
    tpImportProcessed: false,
    csvFile: null,
  });

  const changeHandler = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleFileUpload = e => {
    setState({ ...state, csvFile: e.target.files[0] });
  };

  const getTPImport = async e => {
    e.preventDefault();
    console.log(this.state);
    this.setState({
      wait: 'Please Wait',
      loading: true,
      show: 'noDisp',
      download: '',
    });
    // Create a new FormData instance
    const formData = new FormData();
    formData.append('csvFile', this.state.csvFile);
    formData.append('tpImport', this.state.tpImport);

    const { data } = await api.post(`/api/dt/pricebook/csv`, formData, {
      headers: authHeader,
    });

    console.log(data);
    const error = data.error;
    if (error) {
      this.setState({
        wait: data.error,
        loading: false,
        show: 'btn',
      });
    } else {
      this.setState({
        // brand: data.name,
        download: [data],
        wait: 'Process Files',
        loading: false,
        show: 'btn',
        tpImportProcessed: true,
      });
    }
  };

  return (
    <>
      <div className='task_pb'>
        <input
          type='text'
          name='tpImport'
          value={state.tpImport}
          onChange={changeHandler}
          placeholder='Vendor Name'
        />

        <input type='file' label='Upload File' onChange={handleFileUpload} />

        {state.tpImportProcessed ? (
          <DownloadFiles downloads={state.download} />
        ) : (
          <button className='btn' onClick={getTPImport}>
            Process TP Import File
          </button>
        )}
      </div>

      {state.wait === 'Please Wait' && (
        <div className='overlay'>
          <PacmanLoader size={60} color={'#fff'} />
        </div>
      )}

      <a href='https://brilliantav.sharepoint.com/:x:/g/EfdLieFtBDtGjIPfsFn8ga4B-4guaBivmqBZM_Q8-P0wGg?e=Ip7Jfy&download=1'>
        <button className='btn'> Download New Pricelist Template </button>
      </a>
    </>
  );
};

export default Pricebook;
