import { BUDGETWALKTHROUGH_ACTION_TYPES } from './budget-walkthrough.types';

const tvBackboxNew = 857;
const tvBackboxRetro = 513;
const tvBasicNew = 495;
const tvBasicRetro = 509;
const tvStandardNew = 669 + tvBackboxNew;
const tvStandardRetro = 719 + tvBackboxRetro;
const tvAdvancedNew = 894 + tvBackboxNew;
const tvAdvancedRetro = 994 + tvBackboxRetro;
const mntMed = 1035;
const mntLg = 1135;
const mntXl = 1402;
const auxC4Remote = 1548;
const screen = 3950;
const projMnt = 1490;
const VidWallMnt = 1550;

export const BUDGETWALKTHROUGH_INITIAL_STATE = {
  budget: [],

  projectInfo: {
    opportunityName: '',
    firstName: '',
    lastName: '',
    address: '',
    phoneNumber: '',
    email: '',
  },
  isSystemHidden: {
    System: false,
    Speakers: false,
    'Surround Sound': false,
  },
  totals: [
    { id: 'speakers', total: 0 },
    { id: 'surroundSound', total: 0 },
    { id: 'prewire', total: 0 },
    { id: 'network', total: 0 },
    { id: 'video', total: 0 },
    { id: 'tvs', total: 0 },
    { id: 'largeDisplay', total: 0 },
  ],

  smartHome: [
    {
      id: 'Thermostats',
      quantity: 0,
      c4Price: 554,
      adcPrice: 384,
      prewire: false,
    },
    {
      id: 'Intercom',
      quantity: 0,
      c4Price: 1742,
      adcPrice: 480,
      prewire: true,
      newPrewire: 644,
      retroPrewire: 734,
    },
    {
      id: 'Touch Panels',
      quantity: 0,
      c4Price: 1738,
      adcPrice: 0,
      prewire: true,
      newPrewire: 513,
      retroPrewire: 688,
    },
  ],

  tvSizes: [
    {
      id: '4K 43',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 660,
      mountPrice: mntMed,
      remotePrice: auxC4Remote,
    },
    {
      id: '4K 55',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 1360,
      mountPrice: mntLg,
      remotePrice: auxC4Remote,
    },
    {
      id: '4K 65',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 1660,
      mountPrice: mntLg,
      remotePrice: auxC4Remote,
    },
    {
      id: '4K 75',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 2410,
      mountPrice: mntXl,
      remotePrice: auxC4Remote,
    },
    {
      id: '4K 85',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 3510,
      mountPrice: mntXl,
      remotePrice: auxC4Remote,
    },
    {
      id: '8K 43',
      quantity: 0,
      source: false,
      advNew: 0,
      advRetro: 0,
      basicNew: 0,
      basicRetro: 0,
      standardNew: 0,
      standardRetro: 0,
      tvPrice: 0,
      mountPrice: 0,
      remotePrice: 0,
    },
    {
      id: '8K 55',
      quantity: 0,
      source: false,
      advNew: 0,
      advRetro: 0,
      basicNew: 0,
      basicRetro: 0,
      standardNew: 0,
      standardRetro: 0,
      tvPrice: 0,
      mountPrice: 0,
      remotePrice: 0,
    },
    {
      id: '8K 65',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 3460,
      mountPrice: mntLg,
      remotePrice: auxC4Remote,
    },
    {
      id: '8K 75',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 4910,
      mountPrice: mntXl,
      remotePrice: auxC4Remote,
    },
    {
      id: '8K 85',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 6710,
      mountPrice: mntXl,
      remotePrice: auxC4Remote,
    },
    {
      id: 'Lifestyle/Frame 43',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 1110,
      mountPrice: mntMed,
      remotePrice: auxC4Remote,
    },
    {
      id: 'Lifestyle/Frame 55',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 1660,
      mountPrice: mntLg,
      remotePrice: auxC4Remote,
    },
    {
      id: 'Lifestyle/Frame 65',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 2160,
      mountPrice: mntLg,
      remotePrice: auxC4Remote,
    },
    {
      id: 'Lifestyle/Frame 75',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 3210,
      mountPrice: mntXl,
      remotePrice: auxC4Remote,
    },
    {
      id: 'Lifestyle/Frame 85',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 4510,
      mountPrice: mntXl,
      remotePrice: auxC4Remote,
    },
    {
      id: 'Outdoor TV 43',
      quantity: 0,
      source: false,
      advNew: 0,
      advRetro: 0,
      basicNew: 0,
      basicRetro: 0,
      standardNew: 0,
      standardRetro: 0,
      tvPrice: 0,
      mountPrice: 0,
      remotePrice: 0,
    },
    {
      id: 'Outdoor TV 55',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 3660,
      mountPrice: mntLg,
      remotePrice: auxC4Remote,
    },
    {
      id: 'Outdoor TV 65',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 5160,
      mountPrice: mntLg,
      remotePrice: auxC4Remote,
    },
    {
      id: 'Outdoor TV 75',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 6710,
      mountPrice: mntXl,
      remotePrice: auxC4Remote,
    },
    {
      id: 'Outdoor TV 85',
      quantity: 0,
      source: false,
      advNew: 0,
      advRetro: 0,
      basicNew: 0,
      basicRetro: 0,
      standardNew: 0,
      standardRetro: 0,
      tvPrice: 0,
      mountPrice: 0,
      remotePrice: 0,
    },
    {
      id: 'Good Projector ',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 6000,
      mountPrice: projMnt + screen,
      remotePrice: auxC4Remote,
      largeFormat: true,
    },
    {
      id: 'Better Projector ',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 12000,
      mountPrice: projMnt + screen,
      remotePrice: auxC4Remote,
      largeFormat: true,
    },
    {
      id: 'Best Projector ',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 28000,
      mountPrice: projMnt + screen,
      remotePrice: auxC4Remote,
      largeFormat: true,
    },
    {
      id: '2K Video Wall ',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 143900,
      mountPrice: 0,
      remotePrice: auxC4Remote,
      largeFormat: true,
    },
    {
      id: '4K Video Wall ',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 282560,
      mountPrice: 0,
      remotePrice: auxC4Remote,
      largeFormat: true,
    },
    {
      id: '98" TV ',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 40210,
      mountPrice: VidWallMnt,
      remotePrice: auxC4Remote,
      largeFormat: true,
    },
    {
      id: 'Customer Supplied TV ',
      quantity: 0,
      source: true,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
      tvPrice: 0,
      mountPrice: 0,
      remotePrice: auxC4Remote,
    },
    {
      id: 'Future TV ',
      quantity: 0,
      source: false,
      advNew: tvAdvancedNew,
      advRetro: tvAdvancedRetro,
      basicNew: tvBasicNew,
      basicRetro: tvBasicRetro,
      standardNew: tvStandardNew,
      standardRetro: tvStandardRetro,
    },
  ],
  shadeStyle: [
    {
      id: 'Standard Sheer',
      quantity: 0,
    },
    {
      id: 'Standard Blackout',
      quantity: 0,
    },
    {
      id: 'Paladiom Sheer',
      quantity: 0,
    },
    {
      id: 'Paladiom Blackout',
      quantity: 0,
    },
  ],
  lightingDevice: [
    {
      id: 'Standard Dimmers',
      quantity: 0,
    },
    {
      id: 'Panel Dimmers',
      quantity: 0,
    },
    {
      id: 'SeeTouch Keypads',
      quantity: 0,
    },
    {
      id: 'Paladiom Keypads',
      quantity: 0,
    },
    {
      id: 'Alisse Keypads',
      quantity: 0,
    },
  ],
  misc: [
    {
      item: '',
      quantity: '1',
      price: '0',
    },
    {
      item: '',
      quantity: '1',
      price: '0',
    },
    {
      item: '',
      quantity: '1',
      price: '0',
    },
    {
      item: '',
      quantity: '1',
      price: '0',
    },
    {
      item: '',
      quantity: '1',
      price: '0',
    },
  ],
  miscTotal: 0,
  speakersQuantity: {
    sonosExisting: 0,
    sonosArch: 0,
    sonosSmall: 0,
    sonosInvisible: 0,
    c4Existing: 0,
    c4Arch: 0,
    c4Small: 0,
    c4Invisible: 0,
    c4Hifi: 0,
  },
  surroundQuantity: {
    sonosExisting: 0,
    sonosArch: 0,
    sonosSoundBar: 0,
    sonosInvisible: 0,
    c4Existing: 0,
    c4Arch: 0,
    c4SoundBar: 0,
    c4Invisible: 0,
    c4Hifi: 0,
  },
  audioSystem: null,
  videoSystem: null,
  source: null,
  configType: null,
  configShape: null,
  configSize: 0,
  configFloors: 0,
  configNetwork: null,
  configPrewire: null,
  configPrewireType: null,
  shadeType: null,
  lightingSystem: null,
  lightingRepeaters: 0,
  lightingPanels: 0,
  shadePanels: 0,
  configWAPS: 0,
  securityType: null,
  cameraType: null,
  cameraSize: null,
  securityDevices: [
    {
      id: 'Door Sensors',
      quantity: 0,
      wiredPrice: 83,
      wirelessPrice: 114,
      prewire: true,
      newPrewire: 359,
      retroPrewire: 409,
    },
    {
      id: 'Window Sensors',
      quantity: 0,
      wiredPrice: 83,
      wirelessPrice: 114,
      prewire: true,
      newPrewire: 359,
      retroPrewire: 409,
    },
    {
      id: 'Motion Sensors',
      quantity: 0,
      wiredPrice: 134,
      wirelessPrice: 191,
      prewire: true,
      newPrewire: 359,
      retroPrewire: 409,
    },
    {
      id: 'Garage Door Sensors',
      quantity: 0,
      wiredPrice: 111,
      wirelessPrice: 231,
      prewire: true,
      newPrewire: 409,
      retroPrewire: 459,
    },
    {
      id: 'Glass Breaks',
      quantity: 0,
      wiredPrice: 124,
      wirelessPrice: 191,
      prewire: true,
      newPrewire: 359,
      retroPrewire: 409,
    },
    {
      id: 'Smoke Detectors',
      quantity: 0,
      wiredPrice: 144,
      wirelessPrice: 211,
      prewire: true,
      newPrewire: 409,
      retroPrewire: 459,
    },
    {
      id: 'Heat Detectors',
      quantity: 0,
      wiredPrice: 94,
      wirelessPrice: 214,
      prewire: true,
      newPrewire: 409,
      retroPrewire: 459,
    },
    {
      id: 'CO Detection',
      quantity: 0,
      wiredPrice: 251,
      wirelessPrice: 251,
      prewire: false,
    },
    {
      id: 'Water Detection',
      quantity: 0,
      wiredPrice: 898,
      wirelessPrice: 898,
      prewire: false,
    },
    {
      id: 'Auxilary Keypads',
      quantity: 0,
      wiredPrice: 488,
      wirelessPrice: 488,
      prewire: false,
    },
    {
      id: 'Door Locks',
      quantity: 0,
      wiredPrice: 633,
      wirelessPrice: 633,
      prewire: false,
    },
    {
      id: 'headEndParts',
      mainProc: 1249,
      wired8: 997,
      wired16: 1741,
      wired24: 2485,
      wired32: 3249,
      wired40: 3993,
    },
  ],
  Speakers: [
    {
      id: 'Self-Powered',
      quantity: 0,
    },
    {
      id: 'Architectural',
      quantity: 0,
    },
    {
      id: 'Small Aperature',
      quantity: 0,
    },
    {
      id: 'Invisible',
      quantity: 0,
    },
    {
      id: 'Hi-Fi',
      quantity: 0,
    },
    {
      id: 'Existing Speakers',
      quantity: 0,
    },
  ],
  surroundSound: [
    {
      id: 'In-Ceiling',
      quantity: 0,
    },
    {
      id: 'In-Wall',
      quantity: 0,
    },
    {
      id: 'Soundbar',
      quantity: 0,
    },
    {
      id: 'Invisible',
      quantity: 0,
    },
    {
      id: 'Hi-Fi',
      quantity: 0,
    },
    {
      id: 'Existing Speakers',
      quantity: 0,
    },
  ],
};

export const budgetWalkthroughReducer = (
  state = BUDGETWALKTHROUGH_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_BUDGET_ITEM:
      return { ...state, budget: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_IS_SYSTEM_HIDDEN:
      return { ...state, isSystemHidden: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_AUDIO_SYSTEM:
      return { ...state, audioSystem: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_VIDEO_DIST_SYSTEM:
      return { ...state, videoSystem: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SOURCE:
      return { ...state, source: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS:
      return { ...state, Speakers: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SURROUNDSOUND:
      return { ...state, surroundSound: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_CONFIG_TYPE:
      return { ...state, configType: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_CONFIG_SHAPE:
      return { ...state, configShape: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_CONFIG_SIZE:
      return { ...state, configSize: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_CONFIG_FLOORS:
      return { ...state, configFloors: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_CONFIG_NETWORK:
      return { ...state, configNetwork: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_CONFIG_PREWIRE:
      return { ...state, configPrewire: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_CONFIG_PREWIRETYPE:
      return { ...state, configPrewireType: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_CONFIG_WAPS:
      return { ...state, configWAPS: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS_SONOS_EXISTING:
      return { ...state, speakersSonosExisting: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS_SONOS_ARCH:
      return { ...state, speakersSonosArch: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS_SONOS_SMALL:
      return { ...state, speakersSonosSmall: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS_SONOS_INVISIBLE:
      return { ...state, speakersSonosInvisible: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS_C4_EXISTING:
      return { ...state, speakersC4Existing: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS_C4_ARCH:
      return { ...state, speakersC4Arch: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS_C4_SMALL:
      return { ...state, speakersC4Small: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS_C4_INVISIBLE:
      return { ...state, speakersC4Invisible: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS_C4_HIFI:
      return { ...state, speakersC4Hifi: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SPEAKERS_QUANTITY:
      return { ...state, speakersQuantity: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SURROUND_QUANTITY:
      return { ...state, surroundQuantity: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_TV_SIZES:
      return { ...state, tvSizes: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_TOTALS:
      return { ...state, totals: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SHADE_TYPE:
      return { ...state, shadeType: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_LIGHTING_SYSTEM:
      return { ...state, lightingSystem: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SHADE_STYLE:
      return { ...state, shadeStyle: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_LIGHTING_DEVICES:
      return { ...state, lightingDevice: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_LIGHTING_REPEATERS:
      return { ...state, lightingRepeaters: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_LIGHTING_PANELS:
      return { ...state, lightingPanels: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SHADE_PANELS:
      return { ...state, shadePanels: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SECURITY_TYPE:
      return { ...state, securityType: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SECURITY_DEVICES:
      return { ...state, securityDevices: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_CAMERA_TYPE:
      return { ...state, cameraType: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_CAMERA_SIZE:
      return { ...state, cameraSize: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_PROJECT_INFO:
      return { ...state, projectInfo: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_SMART_HOME:
      return { ...state, smartHome: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.SET_MISC_TOTAL:
      return { ...state, miscTotal: payload };
    case BUDGETWALKTHROUGH_ACTION_TYPES.UPDATE_MISC_ITEMS: {
      const { index, property, value } = action.payload;
      const newState = { ...state };

      if (!newState.misc[index]) {
        newState.misc[index] = {};
      }

      newState.misc[index][property] = value;
      return newState;
    }
    default:
      return state;
  }
};
