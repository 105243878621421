import { combineReducers } from 'redux';

import { budgetReducer } from './budget/budget.reducer';

import { budgetWalkthroughReducer } from './budget-walkthrough/budget-walkthrough.reducer';

export const rootReducer = combineReducers({
  budget: budgetReducer,
  budgetWalkthrough: budgetWalkthroughReducer,
});
