import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../components/Navbar.css';
const Logout = () => {
  // const { logout, isAuthenticated } = useAuth0();
  const { logout } = useAuth0();
  return (
    // isAuthenticated && (
    <span
      className='nav-links'
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
      // onClick={() => logout()}
    >
      Log Out
    </span>
    // )
  );
};

export default Logout;
