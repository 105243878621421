import { Fragment } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo.png';
import AuthNav from './auth-nav/auth-nave.component';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useState } from 'react';
import { classNames } from '../utils/utils';

const navigation = [
  {
    name: 'Service',
    href: '/service',
  },
  {
    name: 'Catalog',
    href: '/catalog',
  },
  {
    name: 'Pricebook',
    href: '/pricebook',
  },
];

const Navbar = () => {
  const location = useLocation();

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(click => !click);

  return (
    <Fragment>
      <div className='navbar-container'>
        <Link to='/' className='navbar-logo'>
          <img src={logo} alt='' />
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          {click ? (
            <FaTimes className='fa-times' />
          ) : (
            <FaBars className='fa-bars' />
          )}
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <div className='nav-item' onClick={handleClick}>
            {navigation.map(item => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  'nav-links',
                  location.pathname === item.href && 'active-link',
                )}
                onClick={handleClick}
              >
                {item.name}
              </Link>
            ))}

            <AuthNav />
          </div>
        </ul>
      </div>
      <div className={click ? 'hide' : ''}>
        <Outlet />
      </div>
    </Fragment>
  );
};

export default Navbar;
